import { tv, VariantProps } from "tailwind-variants";

import {
  alignVariants,
  colorVariants,
  responsiveVariants,
  weightVariants,
  whitespaceVariants,
} from "../constant";

export const textSubtitleVariants = tv(
  {
    base: "font-display font-bold tracking-tighter leading-none",
    variants: {
      size: {
        1: "text-new-xl md:text-new-2xl",
        2: "text-new-lg md:text-new-xl",
        3: "text-new-md md:text-new-lg",
        4: "text-new-base md:text-new-md",
      },
      color: {
        ...colorVariants,
      },
      align: {
        ...alignVariants,
      },
      whitespace: {
        ...whitespaceVariants,
      },
      weight: {
        ...weightVariants,
      },

    },
    defaultVariants: {
      size: 1,
      align: "default",
      whitespace: "normal",
    },
  },
  { responsiveVariants: [ ...responsiveVariants ],

  },
);

export type TextSubtitleVariantsProps = VariantProps<typeof textSubtitleVariants>;
const textSubtitleStyles = (variants: TextSubtitleVariantsProps) => textSubtitleVariants(variants);

export default textSubtitleStyles;
