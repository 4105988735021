import { tv, VariantProps } from "tailwind-variants";

import {
  alignVariants,
  colorVariants,
  responsiveVariants,
  weightVariants,
  whitespaceVariants,
} from "../constant";

export const textBodyVariants = tv(
  {
    base: "font-default font-normal",
    variants: {
      size: {
        1: "text-new-md md:text-new-xl leading-loose",
        2: "text-new-base md:text-new-lg leading-looser",
        3: "text-new-sm md:text-new-base leading-looser",
        4: "text-new-xs md:text-new-sm leading-looser",
      },
      color: {
        ...colorVariants,
      },
      align: {
        ...alignVariants,
      },
      whitespace: {
        ...whitespaceVariants,
      },
      weight: {
        ...weightVariants,
      },

    },
    defaultVariants: {
      size: 1,
      align: "default",
      whitespace: "normal",
      color: "default",
    },
  },
  { responsiveVariants: [ ...responsiveVariants ] },

);

export type TextBodyVariantsProps = VariantProps<typeof textBodyVariants>;
const textBodyStyles = (variants: TextBodyVariantsProps) => textBodyVariants(variants);

export default textBodyStyles;
